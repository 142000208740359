// Fonts
@font-face {
    font-family: 'Pragmatica_Book';
    src: url('../fonts/Pragmatica_Book.woff2') format('woff2'),
    url('../fonts/Pragmatica_Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

// Variables
@import 'variables';


/*.v-application {
    .text-h1 {
        font-size: 40px;
        line-height: 48px;
    }
}*/

body.v-application {
    display: block !important;
}

a:not(.v-btn) {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.4) !important;

    svg {
        path {
            stroke: rgba(0, 0, 0, 0.4) !important;
        }
    }

    &:hover {
        color: #000000 !important;

        svg {
            path {
                stroke: #000000 !important;
            }
        }
    }
}

.a-text {
    color: rgba(0, 0, 0, 0.4) !important;

    &.v-tab--active {
        color: #000000 !important;
    }
}

.v-tabs-slider-wrapper {
    display: none;
}

.v-btn {
    letter-spacing: normal;
}

.btn-upload-files {
    width: 230px;
}

.v-tab {
    text-transform: none !important;
}

.border-radius-12 {
    border-radius: 12px !important;
}

.pa-dialog {
    padding: 40px;
}

.bg-gray {
    background: rgba(242, 242, 242, 1) !important;
}
.v-tooltip__content {
    background: rgba(242, 242, 242, 1) !important;
    width: 230px !important;
    display: flex !important;
    flex-wrap: wrap !important;
    align-items: flex-start !important;
    padding: 20px !important;
    height: 112px !important;
    justify-content: center !important;
    background: #F2F2F2;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    /*transition-duration: 150ms;*/
}

.btn-access {
    padding: 0px 4px!important;
    width: 78px !important;
    height: 24px !important;
    min-width: min-content !important;

    &.v-btn:not(.v-btn--round).v-size--small {
        height: 24px !important;
        min-width: min-content !important;
    }
}

.btn-access>span {
    font-size: 16px;
    line-height: 24px;
}

.min-width-fit-content {
    min-width: fit-content;
}

/*.tooltip-transition-enter-duration {
    transition-duration: 150ms;
}*/

.v-tooltip__content.menuable__content__active {
    opacity: 1!important;
}

.tooltip-block {
    font-size: 16px !important;
    line-height: 24px !important;
    align-self: center;
}
.v-list {
    padding: 2px !important;

    a {
        color: #000000 !important;
        background-color: transparent !important;

        &:hover {
            color: rgba(0, 0, 0, 0.4) !important;
        }

        &:before {
            background-color: transparent !important;
        }
    }
}

.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 40px 15px;
    margin: auto;
}

.form-signin .checkbox {
    font-weight: 400;
}

.form-signin .form-floating:focus-within {
    z-index: 2;
}

.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.shadowless {
    box-shadow: 0 0 !important;
}

.hoverless {
    &:hover {
        &:before {
            opacity: 0 !important;
        }
    }
}

.borderless fieldset {
    border: 0 !important;
}

.p-0-i {
    padding: 0 !important;
}

.min-height {
    min-height: 180px;
}

.default-border-radius {
    border-radius: 4px !important;
}

img {
    max-width: 100%;
    max-height: 100%;
}

#header {
    position: fixed;
    background: #FFFFFF;
    width: 100%;
    z-index: 100;
}

.pt-spacer {
    padding-top: 110px !important;
}

.pt-spacer-records {
    padding-top: 160px !important;
}

.video-image {
    position: relative;
    width: 100%;
    height: 100%;
}

.default-font-size {
    font-size: 16px !important;
    line-height: 24px !important;
}


.text-image {
    position: relative;
    width: 100%;
    height: 100%;
}

.text-image-empty {
    position: relative;
    width: 100%;
    height: 100%;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to top right, transparent calc(50% - 1px), #aaa, transparent calc(50% + 1px))
    }

    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom right, transparent calc(50% - 1px), #aaa, transparent calc(50% + 1px))
    }
}

.position-absolute {
    position: absolute !important;
    z-index: 1;
}

.position-relative {
    position: relative !important;
}

.position-fixed {
    position: fixed !important;
}

.behance-iframe {
    left: 0;
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 129.4118%;
    margin-bottom: 40px;
}

.loading-overlay {
    .v-overlay__content {
        position: fixed;
        bottom: 50%;
        margin-bottom: -25px;
    }
}

.mtop-video {
    margin-top: 20px;
}

.mtop {
    margin-top: 20px;
}

.v-application {
    .v-breadcrumbs {
        a {
            color: rgba(0, 0, 0, 0.4) !important;

            &:hover {
                color: #000000 !important;
            }
        }

        .v-breadcrumbs__item--disabled {
            color: #000000 !important;
        }
    }
}

.black--text-i {
    color: #000000 !important;
}

#plus-button {
    display: inline-block;
}

a.components-button.edit-post-fullscreen-mode-close.has-icon {
    display: none !important;
}

.right-0 {
    right: 0;
}

.top-0 {
    top: 0;
}

.right-10 {
    right: 10px;
}

.top-10 {
    top: 10px;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.w-10p {
    width: 10px !important;
}

.bg-tr-white {
    background: rgba(255, 255, 255, 0.5);
}

.z-index-100 {
    z-index: 100;
}

.overflow-visible {
    overflow: visible;
}

.cursor-move {
    cursor: move !important;
}

.comment-responded {
    margin-left: 50px;

    .icon-comment-responded {
        position: absolute !important;
        left: -30px;
        top: 10px;
    }
}

.record-textarea {
    .v-input__slot {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.comment-block-items {
    padding-left: 50px;
    position: relative;

    .icon-comment-responded {
        position: absolute !important;
        left: 20px;
        top: 28px;
    }
}

.bottom-0 {
    bottom: 0;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 2px !important;
}

.v-application .v-input {
    padding-top: 30px !important;
}

.v-application .v-input:not(.v-file-input):not(.v-input--switch):not(.v-input--radio-group) .v-label, .v-textarea.v-text-field--box.v-text-field--single-line:not(.v-input--dense) .v-label {
    transform: none !important;
    top: -26px;
    left: 0px !important;
    animation: none;
}

.v-application .v-input legend {
    width: 0px !important;
    border: none !important;
    opacity: 0 !important;
    background-color: transparent !important;
    visibility: hidden;
    transition: none !important;
}

.v-application .v-input input[type=text], .v-application .v-input input[type=password], .v-application .v-input input[type=email], .v-application .v-input textarea {
    padding: 0 16px !important;
}

.v-application--is-ltr .v-text-field .v-input__append-inner {
    padding-right: 16px !important;

    .v-btn {
        margin-top: -6px;
    }

    .v-chip {
        margin-top: -6px;
    }

    .button-slot-append {
        position: absolute;
        right: 2px;
        bottom: 2px;
        background: #FFFFFF;
        width: 45px;
        height: 45px;
    }
}

.v-select__selection {
    padding: 0 16px !important;
}

.comments-upload-item {
    width: 112px;
    border: 1px solid rgba(0, 0, 0, 0.05);

    img {
        max-width: 100%;
    }
}

.editor {
    .menubar {

        &__button {
            border: none;
            background: transparent;
            font-size: 1em;
            padding: 0 5px;
            cursor: pointer;
        }
    }

    .editor__content {
        max-height: 400px;
        overflow: auto;
        border: #eee solid 1px;
        padding: 5px 20px;
        border-radius: 5px;
        line-height: 20px;

        .ProseMirror {
            min-height: 200px;
        }
    }
}

.bg-transparent {
    background: transparent !important;

    * {
        background: transparent !important;
    }
}

.video-container {
    padding: 58% 0 0 0;
    position: relative;

    .video-iframe, .video-unavailable {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-unavailable {
        background: #000000;
        color: #FFFFFF;
    }
}

.video-responsive {
    width: 100%;
}

.attached-file {
    margin-bottom: 0px;
}

.attached-masonry > div .attached-file:last-child {
    margin-bottom: 0px;
}

.gender-switch {
    .v-input--switch {
        margin-top: 0 !important;
    }

    .v-input--switch__thumb {
        background: #FFFFFF !important;
        color: #000000 !important;

        &:before {
            display: inline-block;
            font: normal normal normal 24px/1 "Material Design Icons";
            font-size: inherit;
            text-rendering: auto;
            line-height: inherit;
            -webkit-font-smoothing: antialiased;
            content: "\F12C";
        }
    }

    .v-input--switch__track {
        opacity: 1 !important;
        background: #000000 !important;
    }
}

.mention-item {
    padding: 4px 0;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    margin-bottom: 4px;

    .user--name {
        color: #000000;
    }

    .user--nickname {
        color: rgba(0, 0, 0, 0.4);
    }
}

.tooltip-inner > div {
    padding: 4px 8px;
    max-height: 175px;
    overflow: auto;

    .mention-item:last-child {
        margin-bottom: 0;
    }

}

.tooltip-inner > div::-webkit-scrollbar {
    width: 4px;
}

.tooltip-inner > div::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
}

.tooltip-inner > div::-webkit-scrollbar-thumb {
    background: #444444;
}

.tooltip-inner > div::-webkit-scrollbar-thumb:hover {
    background: #000000;
}

.mention-selected {
    background: rgba(0, 0, 0, 0.05);
}

.tooltip[x-placement^="bottom"], .tooltip[x-placement^="top"] {
    width: 280px;
    margin-top: 5px;
    margin-left: 280px;
    padding: 4px 0;
    z-index: 100;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.img-mw {
    max-width: 100%;
    width: 100%;
}

.records-toolbar {
    width: 100%;
    padding: 20px 0;
    z-index: 100;
    background: #FFFFFF;
}

.v-menu__content--fixed {
    z-index: 100 !important;
    min-width: 168px !important;

    .v-list {
        padding: 6px 2px !important;

        .v-list-item {
            min-height: 42px !important;
        }
    }
}

.comment-preview-text {
    font-size: 16px;
    line-height: 24px;
    max-height: 100px;
}

.v-breadcrumbs {
    padding-left: 0 !important;
    overflow: hidden;

    &.breadcrumbs-scrolled {
        li {
            font-size: 16px !important;
            line-height: 48px !important;
        }
    }

    .v-breadcrumbs__divider {
        padding: 0 12px !important;
    }
}

.records-add-block {
    .v-card__text {
        padding: 20px;
    }
}

.record-card-block {
    padding: 40px 0;
}

.mb-80 {
    margin-bottom: 80px;
}

.pa-20 {
    padding: 20px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.bg-gray-custom {
    background: rgba(0, 0, 0, 0.05) !important;
}

.v-chip.v-size--default {
    font-size: 16px;

    &.chip-square {
        width: 32px;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.v-chip.v-size--small {
    &.chip-square {
        width: 24px;
        justify-content: center;
    }
}

.v-chip.v-size--large {

}

.v-text-field--filled:not(.v-textarea) > .v-input__control > .v-input__slot, .v-text-field--full-width:not(.v-textarea) > .v-input__control > .v-input__slot, .v-text-field--outlined:not(.v-textarea) > .v-input__control > .v-input__slot {
    max-height: 48px !important;
    min-height: 48px !important;
}

.btn-upload-files.v-btn:not(.v-btn--round).v-size--default {
    padding: 12px 12px !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    padding: 12px 16px !important;
}

.v-btn:not(.v-btn--round).v-size--small {
    padding: 0 4px !important;
}

.v-btn__content {
    max-width: 100%;

    span {
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.v-btn:not(.v-btn--outlined).accent {
    color: #000000 !important;
}

.v-application .v-btn.accent--text {
    color: #000000 !important;
}

.v-expansion-panel {
    border: 1px solid rgba(0, 0, 0, 0.4);

    &:before {
        box-shadow: none !important;
    }
}

.v-dialog {
    box-shadow: none !important;
}

.overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nowrap-text {
    white-space: nowrap;
}

.search-text-field-container {
    position: fixed;
    width: 100%;
    left: 0;
    top: 64px;
    background: #FFFFFF;
    z-index: 100;

    .v-input {
        padding-top: 0 !important;

        input[type=text] {
            padding-left: 0 !important;
            padding-right: 0 !important;
            font-size: 28px;
            line-height: 36px;
        }
    }

    .v-input__slot:before {
        border-top: 0 !important;
    }

    .v-text-field__details {
        display: none !important;
    }
}


.theme--light {
    .v-avatar {
        &.outlined {
            border: 1px solid rgba(0, 0, 0, 0.2) !important;

            &:hover {
                border-color: #000000;
            }
        }
    }

    .v-footer {
        background-color: #FFFFFF !important;
    }

    .v-btn {
        &:hover {
            &:before {
                opacity: 0 !important;
            }
        }

        &.primary {
            &:hover {
                background-color: rgba(0, 0, 0, 0.8) !important;
            }
        }

        &.accent {
            &:hover {
                background-color: #B3F200 !important;
            }
        }

        &.square-custom-btn {
            padding-left: 16px !important;
            padding-right: 16px !important;
            letter-spacing: normal;
            min-width: 20px !important;
        }

        &.btn-fab-white {
            background: #FFFFFF;
        }
    }

    .v-btn--outlined {
        border-color: rgba(0, 0, 0, 0.2) !important;
        color: #000000 !important;

        &:not(.hover-bordered) {
            &:hover {
                border-color: #000000 !important;
                background: #000000 !important;
                color: #FFFFFF !important;

                svg:not(.heart-svg) {
                    path {
                        stroke: #FFFFFF;
                    }
                }

                svg.heart-svg {
                    path {
                        fill: #FFFFFF;
                    }
                }
            }
        }

        &.hover-bordered {
            &:hover {
                border-color: #000000 !important;
            }
        }
    }

    .btn-gray-hover {
        color: rgba(0, 0, 0, 0.4) !important;
        img {
            display: none;
        }
        .img-default {
            display: inline-flex;
        }
        &:hover {
            .img-default {
                display: none;
            }
            .img-hover {
                display: inline-flex;
            }
        }

        &.btn-gray-hover-active {
            border-color: #000000 !important;
            color: #000000 !important;
            .img-default {
                display: none;
            }
            .img-hover {
                display: none;
            }
            .img-active {
                display: inline-flex;
            }
            &:hover {
                color: #FFFFFF !important;
                .img-default {
                    display: none;
                }
                .img-active {
                    display: none;
                }
                .img-hover {
                    display: inline-flex;
                }
            }
        }
    }

    .hover-bordered {
        &:hover {
            border-color: #000000 !important;
        }
    }

    .grey--text svg path {
        stroke: #9e9e9e;
    }

    .v-sheet--outlined {
        border-color: rgba(0, 0, 0, 0.2) !important;

        &.course-list-item {
            margin: 0 0 80px 0;
            height: 300px;

            &:hover {
                border-color: #000000 !important;
            }

            &.course-owner {
                background: rgba(0, 0, 0, 0.05) !important;
                border: 0 !important;
            }

            .v-card__text {
                padding: 20px;
            }

            .course-list-item-title {
                margin: 48px 0 16px 0;
            }

            .course-list-item-labels {
                position: absolute;
                left: 20px;
                top: 20px;
            }

            .course-list-item-descr {
                max-height: 72px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    .comments-textarea {
        position: relative;
        padding: 0 !important;

        .v-input__slot {
            margin: 0 !important;
            padding: 0 !important;
        }

        .v-text-field__details {
            width: 100%;
            position: absolute;
            bottom: 0px;
        }

        textarea {
            margin: 0 !important;
            padding: 20px !important;
        }
    }
}

.courses-list {
    margin-bottom: 40px;

    .course-list-item:last-child {
        margin-bottom: 0;
    }
}

.handle-btn {
    &:before {
        background: none !important;
    }

    &:active {
        background: #000000 !important;
        cursor: grabbing !important;


        i {
            color: #FFFFFF !important;
        }
    }
}

.item-description {
    p {
        width: 50%;
    }

    .slick-slider {
        margin: 40px -60px;
        width: calc(100vw - 60px);

        &.gallery-type-image {
            max-height: 760px;
        }

        .slick-list {
            padding: 0 50px;
        }

        .slide-content {

            > div {
                margin: 0 10px;
                max-width: 1320px;
                max-height: 760px;
            }
        }

        .slick-next {
            z-index: 1;
            right: 10px;

            &:before {
                opacity: 0 !important;
            }
        }

        .slick-prev {
            z-index: 1;
            left: 10px;

            &:before {
                opacity: 0 !important;
            }
        }

        .slick-disabled {
            display: none !important;
        }
    }
}

.user-menu-block {
    min-width: 185px;
    position: fixed;
    right: 0px;
    top: 0px;
    padding-top: 15px !important;
    padding-right: 60px;
    z-index: 102;
}

.px-c-10 {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

.notifications-column {
    margin-top: -60px;

    background: #F2F2F2 !important;

    .notifications-column-inner {
    }
}

.avatar-user {
    position: relative;

    .avatar-hover-del {
        position: absolute;
        width: 100%;
        height: 100%;
        display: none;
    }

    &:hover {
        .avatar-hover-del {
            display: flex;
        }
    }
}

.v-input {
    &.only-select {
        max-width: 220px;
        padding-top: 0 !important;

        .v-input__slot {
            margin-bottom: 0;
        }

        .v-text-field__details {
            display: none;
        }
    }
}

.menu-avatar {
    div {
        height: 30px;
    }
}

.notifications-column {
    width: 25%;
    height: calc(100vh + 110px);
    position: fixed;
    right: 0;
    top: 0;
    padding-top: 220px !important;
    overflow: auto;
    z-index: 101;

    .notifications-column-inner {
        height: calc(100vh - 230px);
        overflow: auto;
        /* disable scrollbar Firefox */
        margin: 0;
        scrollbar-width: none;
        -ms-overflow-style: none;
        overflow-y: scroll;
        /* end disable scrollbar Firefox */
    }
}

.notifications-column-inner::-webkit-scrollbar {
    width: 0px;
}

/* Track */
.notifications-column-inner::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.notifications-column-inner::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.notifications-column-inner::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.v-menu__content{
    border-radius: 0 !important;
    z-index: 110 !important;

    .v-list {
        border-radius: 4px !important;
    }
}

.method-breadcrumbs {
    max-width: 75%;
}

.theme--light.v-btn--active::before {
    opacity: 0 !important;
}

.social-block > .btn-fab-white{
    border: 0 solid currentColor !important;
}

@media only screen and (max-width: 960px) {

    #header {
        display: block !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        min-height: 68px;
        position: relative;
        background-color: #FFFFFF;
    }
    .item-description {
        p {
            width: 100%;
        }
    }

    .item-description .slick-slider {
        margin: 40px -60px;
        width: calc(100vw - -25px);
    }

    .pt-spacer {
        padding-top: 20px !important;
    }

    .min-width-fit-content {
        min-width: fit-content;
    }

    .user-menu-block {
        position: fixed;
        left: 2px;
        top: 0px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;
        width: 100%;
        background-color: #FFFFFF;
        z-index: 101;
    }

    .attached-file {
        margin-bottom: 0px;
    }

    .method-breadcrumbs {
        width: 100%;
        max-width: 100%;
        padding: 50px 0 10px 0 !important;
        background: none !important;
    }
    .v-application.theme--light {
        .v-sheet--outlined {

            &.course-list-item {
                margin: 0 0 20px 0;
                height: auto;
                padding-top: 44px;

                .v-card__text {
                    padding: 12px;
                }

                .course-list-item-title {
                    margin-top: 12px;
                    margin-bottom: 12px;
                }

                .course-list-item-row {
                    flex-wrap: wrap-reverse !important;
                }

                .course-list-item-labels {
                    left: 12px;
                    top: 12px;
                }

                .course-list-item-descr {
                    max-height: none;
                }
            }
        }
        .px-c-10 {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }

    .v-menu__content--fixed {
        width: calc(100% - 24px) !important;
        max-width: 100% !important;
    }

    #footer {
        .footer-col {
            padding-top: 6px !important;
            padding-bottom: 6px !important;
        }
    }

    .v-application.theme--light {
        .text-h1 {
            font-size: 28px !important;
            line-height: 36px !important;
        }

        .text-h2 {
            font-size: 24px !important;
            line-height: 32px !important;
        }
    }

    .notification-block-width {
        width: 360px !important;
    }

    .notifications-column {
        width: 0 !important;
        z-index: 105;
        visibility: hidden;
        top: 0;
        transition: 0.5s;
        padding: 0 !important;
        background-color: transparent;
        height: calc(100vh + 60px);

        .mobile-inner {
            background: #F2F2F2 !important;
            padding-top: 200px;
        }

        .notifications-column-inner {
            padding-left: 0 !important;
            padding-right: 0 !important;
            height: calc(100vh - 180px);


            .px-c-10 {
                padding-left: 0px !important;
                padding-right: 0px !important;
            }

            .v-card {
                display: none;
                min-width: 250px;

                .v-card__text {
                    padding: 12px !important;
                }
            }
        }

        &.on-display {
            width: 100% !important;
            visibility: visible;
            background-color: rgba(0, 0, 0, 0.6) !important;

            .notifications-column-inner {
                padding: 10px;

                .px-c-10 {
                    padding-left: 10px !important;
                    padding-right: 10px !important;
                }

                .v-card {
                    display: block;
                }
            }
        }
    }

    .responsive-small-fab-btn {
        width: 24px !important;
        height: 24px !important;
    }

    .method-breadcrumbs {
        margin-top: 20px !important;
        font-size: 24px !important;
        line-height: 32px !important;
        background: #FFFFFF;

        &.breadcrumbs-scrolled {

        }
    }

    .notification-status-label {
        position: absolute;
        right: 0;
        top: 0;
    }


    .tooltip[x-placement^="bottom"], .tooltip[x-placement^="top"] {
        width: calc(100vw - 40px);
        margin-top: 5px;
        margin-left: calc(100vw - 43px);
        padding: 4px 0;
        z-index: 100;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
    }

    .v-application {
        .pt-spacer {
            .records-toolbar {
                top: auto !important;
                bottom: 0 !important;
                margin-bottom: 0 !important;
            }
        }
    }

    .btn-edit-mb {
        margin-bottom: 20px !important;
    }

    .menu-user {
        width: calc(100% - 20px) !important;
        left: 10px !important;
    }

    .social-block > .btn-fab-white{
        border: 0 solid currentColor !important;
    }

    .theme--light {
        .profile-tabs {
            > .v-tabs-bar {
                height: auto !important;
            }

            .v-slide-group__prev {
                display: none !important;
            }

            .v-slide-group__content {
                display: block !important;
                width: 100% !important;
            }

            .v-tab {
                float: left;
                font-size: 20px !important;
                line-height: 28px !important;
            }
        }
    }
}

@media only screen and (max-width: 600px) {

    .menu-user {
        width: calc(100% - 20px) !important;
        left: 10px !important;
    }

    #header {
        min-height: 56px !important;

        .v-btn {
            &.v-btn--fab {
                &.v-size--default {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        .menu-avatar {
            width: 36px !important;
            min-width: auto !important;
            max-width: 36px !important;
            max-height: 36px !important;
        }
    }
    #footer {
        margin-bottom: -160px;
    }

    .min-width-fit-content {
        min-width: fit-content;
    }

    .notification-block-width {
        width: 280px !important;
    }

    .mtop-video {
        margin-top: 12px;
    }

    .mob-p-12 {
        padding: 12px !important;
    }

    .mr-2.v-btn.btn-fab-white{
        margin-right: 7px !important;
        margin-bottom: 7px !important;
    }

    .btn-edit-mb {
        margin-bottom: 20px !important;
    }

    .v-application.theme--light .text-h2 {
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .main {
        padding: 0px 0px 84px !important;
    }

    .border-r-12 {
        border-radius: 12px !important;
    }

    /*.mob-preview-text {
        width: 355px !important;
        height: 361px !important;
    }*/

    .mtop {
        margin-top: 12px;
    }

    .pa-dialog {
        padding: 24px !important;
    }

    .v-application.theme--light {
        .text-h1 {
            font-size: 24px !important;
            line-height: 32px !important;
        }

        .v-breadcrumbs--large li {
            font-size: 24px !important;
            line-height: 32px !important;
        }

        .v-sheet--outlined.course-list-item {
            margin: 0 0 40px 0;
            height: auto;
            padding-top: 44px;
        }
    }

    .lecture-operation-block {
        width: 100%;
        position: fixed;
        padding: 10px 0;
        bottom: 0;
        background: #FFFFFF;
        z-index: 100;
    }

    .item-description .slick-slider {
        width: 100%;
        margin: 40px 0px;
    }

    .item-description {

        .slick-slider {
            margin: 40px 0px;
            max-height: 100%;

            &.gallery-type-image {
                max-height: 760px;
                max-width: 100%;
            }

            img {
                max-width: 100%;
            }

            .slick-list {
                margin: 0 -10px;
                padding: 0;
            }

            .slide-content {

                > div {
                    margin: 0 10px;
                    max-width: 1320px;
                    max-height: 760px;
                }
            }

            .slick-next {
                width: 24px;
                height: 24px;
                right: 0px;
                top: auto;
                bottom: -40px;
            }

            .slick-prev {
                width: 24px;
                height: 24px;
                z-index: 1;
                left: 0px;
                top: auto;
                bottom: -40px;
            }
        }
    }

    .comment-block-items {
        padding-left: 0px;
        position: relative;

        .icon-comment-responded {
            display: none;
        }


        .comment-responded {
            margin-left: 20px;

            .icon-comment-responded {
                display: block;
                position: absolute !important;
                left: -20px;
                top: 10px;
            }
        }
    }

    .v-btn {
        &.sq-btn-d-mobile {
            width: 48px !important;
            min-width: auto !important;
        }
    }

    .pa-mobile {
        padding: 10px !important;
    }

    .mb-mobile {
        margin-bottom: 12px !important;
    }

    .line-height-mobile {
        line-height: 28px !important;
    }

    .font-sz-16 {
        font-size: 16px;
    }

    .record-card-block {
        padding: 20px 0;
    }

    .theme--light {
        .comments-textarea {
            textarea {
                padding: 12px 10px 36px 10px !important;
            }
        }

        .profile-tabs {
            > .v-tabs-bar {
                height: auto !important;
            }

            .v-slide-group__prev {
                display: none !important;
            }

            .v-slide-group__content {
                display: block !important;
                width: 100% !important;
            }

            .v-tab {
                float: left;
                font-size: 20px !important;
                line-height: 28px !important;
            }
        }
    }
    .sm-default-font-size {
        font-size: 16px !important;
    }

    .profile-edit-btn {
        position: absolute;
        left: 0;
        top: -22px;
        z-index: 100;
    }

    .search-text-field-container {
        position: relative;
        left: auto;
        top: auto;
        margin-top: -28px;
        margin-bottom: 20px;
        padding-left: 0 !important;
        z-index: 99;

        .v-input {
            padding-top: 0 !important;

            input[type=text] {
                padding-left: 0 !important;
                padding-right: 0 !important;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    .social-block > .btn-fab-white{
            border: 0 solid currentColor !important;
    }
}

@media only screen and (width: 375px) {
    .mob-preview {
        width: 355px !important;
        /*height: 503px !important;*/
    }
}
